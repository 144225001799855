.service-card{
  transition: 0.3s;
}
 .service-card:hover{
  transform: translate(0 , -10px);
 }
.transparent{
     
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  
}
.trans{
  transition: 0.3s ease-in-out;
  display: inline-block;
}
.transparent:hover .trans {
  transform: translateX(7px); /* Moves the arrow 10px to the right */
}