.bg-gradient {
  background: linear-gradient(rgb(14, 50, 206), rgb(110, 137, 255));
}
/* Reduce card width and set colors */
.mySwiper {
  width: 20rem; /* Adjust width of the Swiper container */
  height: 100%;
}

.swiper-slide {
  width: 100%; /* Full width within the swiper container */
  height: 430px; /* Set desired height */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 20px;
  color: #0c0a0a;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Background colors for different slides */
.swiper-slide:nth-child(1) {
  
  background-color: #f8f8f8; /* Red */
  /* border-radius: 10%;   */
}

.swiper-slide:nth-child(2) {
  background-color: white; /* Blue */
}

.swiper-slide:nth-child(3) {
  background-color: white; /* Green */
}

.swiper-slide:nth-child(4) {
  background-color: white; /* Pink */
}

.swiper-slide:nth-child(5) {
  background-color: white; /* Yellow */
}
.highlight-card {
  background-color: #0000006a;
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.238); */
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  cursor: pointer;
  height: 7.5rem;
  /* border-radius: 5%; */
}

 

.highlight-card h5 {
  font-size: 17px;
  font-weight:5;
  color: #eeeeee;
}

.highlight-card p {
  color: #e9e9e9;
  font-size: 14px;
  line-height: 1.5;
}

.redirect-arrow {
  font-weight: bold;
  color: #007bff;
  margin-left: 5px;
}

.slick-dots li button:before {
  color: #007bff;
}

.slick-dots li.slick-active button:before {
  color: #0056b3;
}


