/* Reset margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Main container for animated sections */
.animated-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

/* Scroll sections with sticky effect */
.scroll-section {
  width: 100%;
  height: 100vh;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 2rem; */
  position: relative;
  /* Default state */
  top: 0;
  transition: all 0.3s ease-in-out;
}

/* Ensure Sticky Effect Works Only for Targeted Sections */
.scroll-section.sticky {
  position: sticky;
  top: 0;
}

/* Scroll effect only on first 2 sections */
@media (min-width: 768px) {
  .scroll-section:nth-of-type(1),
  .scroll-section:nth-of-type(2) {
    animation: scaleOnScroll linear both;
    animation-timeline: scroll();
  }
}

@keyframes scaleOnScroll {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

/* Normal sections outside container */
.normal-section {
  width: 100%;
  height: 80vh;
  background-color: lightgray;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
}
