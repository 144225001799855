.position-relative {
    position: relative;
    overflow: hidden; /* Ensure the overlay stays within the image bounds */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 93.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Black blur background */
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: flex-start; /* Align text to the top */
    justify-content: flex-start; /* Align text to the left */
    padding: 10px; /* Add some padding around the text */
    border-radius: inherit; /* Match the image's rounded corners */
    margin-left: 12px;
    border-radius: 5px;
  }
  
  .overlay-text {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .position-relative:hover .overlay {
    opacity: 1;
  }
  