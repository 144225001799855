/* Navbar background and text color */

:root {
  --mdb-highlight-bg-color: transparent !important;
}

.navbar {
  background: #3b82f6; /* Navbar background color */
}

.navbar-nav .nav-link {
  color: white !important; /* Set text color to white */
  position: relative; /* Ensure position for underline effect */
  text-decoration: none; /* Remove underline from nav links */
  font-weight: normal; /* Optional: Adjust font weight if needed */
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Underline effect on active menu items */
 

/* Underline effect on hover */
.navbar-nav .nav-link:hover {
  color: #d1d5db; 
}

/* Dropdown menu styling */
.navbar-nav .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  min-width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  border-radius: 0;
  background-color: #3b82f6; /* Match dropdown background to navbar */
  z-index: 1000;
  opacity: 0;
  max-height: 0;
  overflow: hidden; 
  transition: opacity 0.3s ease-out, max-height 0.3s ease-out; /* Include max-height transition */
  animation: none; /* Ensure no animation by default */
}

/* Keyframes for shutter animation */
@keyframes shutterExpand {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 320px; /* Adjust based on the content height */
    opacity: 1;
  }
}

/* Apply animation when dropdown is shown */
.dropdown-menu.show {
  animation: shutterExpand 0.3s ease-out forwards;
}

/* Dropdown item styling */
.dropdown-menu > .dropdown-item {
  padding: 0.5rem 1rem;
  color: white; /* Set dropdown item text color to white */
  text-align: left;
  white-space: nowrap;
  position: relative;
  transition: color 0.3s ease;
  /* --mdb-dropdown-state-background-color: transparent; */
  
}

/* .dropdown-item.active, .dropdown-item:active {
  color: #fff;  
  background-color: #6e66664a;  
}

 
.dropdown-item {
  color: #fff; 
} */

/* Underline effect on active dropdown item */
/* .dropdown-menu > .dropdown-item.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
} */

/* Hover effect */
.dropdown-menu > .dropdown-item:hover {
  background-color: #2563eb; /* Slightly darker background on hover */
}

/* Dropdown toggle styling */
.nav-link.dropdown-toggle {
  display: flex;
  align-items: center;
}

/* Dropdown arrow */
.dropdown-arrow {
  margin-left: 0.5rem;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.3rem solid #fff;  
  transition: transform 0.3s ease;
}

/* Rotate the arrow when the dropdown is open */
.dropdown-arrow.open {
  transform: rotate(180deg);
}

/* Dropdown submenu styling */
.dropdown-menu .row {
  display: flex;
}

.dropdown-menu .col-lg-3 {
  border-right: 1px solid #ddd;
}

.dropdown-menu .col-lg-4 {
  padding-left: 15px;
}
.dropdown-item.active, .dropdown-item:active {
  
  background-color: #1c191965;
  transition: .3s ease;

}
.dropdown-menu {
  --mdb-dropdown-state-background-color: inherit !important;
}
.navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px; /* Adjust thickness of underline */
  width: 100%;
  background-color: #fff;  
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

/* Display underline on hover */
.navbar-nav .nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.drawer-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.drawer-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.drawer-exit {
  transform: translateX(0);
  opacity: 1;
}

.drawer-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}
/* .nav-link.active{
  border-bottom: 1px solid white;
} */
.menulink{
  font-size: 16px;
  font-weight: 400;
  border-bottom: 2px solid white;
}
.logoimg{
  height: 3.20rem !important;
}
@media only screen and (max-width: 600px) {
  .logoimg{
    height: 3rem !important;
  }
}
 
